@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

#list-container {
  width: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2f363e;
}

#list {
  position: relative;
  width: auto;
  min-height: 500px;
  padding: 50px;
  background: #2f363e;
  box-shadow: 25px 25px 75px rgba(0, 0, 0, 0.25),
    10px 10px 70px rgba(0, 0, 0, 0.25),
    inset 5px 5px 10px rgba(0, 0, 0, 0.5),
    inset 5px 5px 20px rgba(255, 255, 255, 0.2),
    inset -5px -5px 15px rgba(0, 0, 0, 0.75);
  border-radius: 30px;
}

#list > h3 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
}

#list-item {
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}

#list-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  background: #1f83f2;
  border-radius: 25px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25),
    inset 2px 2px 5px rgba(255, 255, 255, 0.5),
    inset -3px -3px 5px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

#list-item:hover::before {
  width: 100%;
}

#list-item > #rank {
  position: relative;
  min-width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border-radius: 50%;
  margin-right: 10px;
}

#list-item > #rank::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background: #2f363e;
  border-radius: 30px;
  transform: scale(0);
  transition: 0.5s;
}

#list-item:hover > #rank::before {
  transform: scale(1);
}

#list-item > #rank > span {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  transition: 0.5s;
}

#list-item > #name {
  position: relative;
  line-height: 1.15rem;
}

#list-item > #name > h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #bcbcc0;
  transition: 0.5s;
}

#list-item > #name > p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #1cc40d;
  transition: 0.5s;
}

#list-item:hover #name > h4,
#list-item:hover #name > p {
  color: #fff;
}