.loader:empty {

    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    /* width: 6em;
    height: 6em; */

    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);

    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    border-radius: 50%;
    -webkit-animation: spin 1.1s linear infinite;
    animation: spin 1.1s infinite linear;

}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}