/* html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    background: #fff;
    overflow-y: hidden;
  } */
  .bounce {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font: normal bold 6rem "Product Sans", sans-serif;
    white-space: nowrap;
  }
  .char {
    animation: bounce 0.75s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
    display: inline-block;
    transform: translate3d(0, 0, 0);
    margin-top: 0.5em;
    text-shadow: rgba(0, 0, 0, 0.4) 0 0 0.05em;
    font: normal bold 6rem "Product Sans", sans-serif;
  }
  .char:nth-child(1) {
    animation-delay: 0s  ;
    color: #4285f4;
  }
  .char:nth-child(2) {
    animation-delay: 0.25s  ;
    color: #ea4335;
  }
  .char:nth-child(3) {
    animation-delay: 0.5s  ;
    color: #fbbc05;
  }
  .char:nth-child(4) {
    animation-delay: 0.75s ;
    color: #4285f4;
  }
  .char:nth-child(5) {
    animation-delay: 1s  ;
    color: #34a853;
  }
  .char:nth-child(6) {
    animation-delay: 1.25s  ;
    color: #ea4335;
  }
  .char:nth-child(7) {
    animation-delay: 1.5s  ;
    color: #fbbc05;
  }
  .char:nth-child(8) {
    animation-delay: 1.75s  ;
    color: #4285f4;
  }
  .char:nth-child(9) {
    animation-delay: 2s  ;
    color: #34a853;
  }
  .char:nth-child(10) {
    animation-delay: 2.25s  ;
    color: #ea4335;
  }
  .char:nth-child(11) {
    animation-delay: 2.5s  ;
    color: #fbbc05;
  }
  .char:nth-child(12) {
    animation-delay: 2.75s  ;
    color: #4285f4;
  }
  .char:nth-child(13) {
    animation-delay: 3s  ;
    color: #34a853;
  }
  .char:nth-child(14) {
    animation-delay: 3.25s  ;
    color: #ea4335;
  }
  @keyframes bounce {
    0% {
      transform: translate3d(0, 0, 0);
      text-shadow: rgba(0, 0, 0, 0.4) 0 0 0.05em;
    }
    100% {
      transform: translate3d(0, -1em, 0);
      text-shadow: rgba(0, 0, 0, 0.4) 0 1em 0.35em;
    }
  }