/* body{
    text-align: left;
    background-color: #97b7d6;
} */
.main-body {
    padding: 15px;
}
.profilecard {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.profilecard {
    position: relative!important;
    display: flex!important;
    flex-direction: column!important;
    min-width: 0!important;
    word-wrap: break-word!important;
    /* background-color: #fff!important;
    background-clip: border-box!important;
    border: 0 solid rgba(0,0,0,.125)!important;
    border-radius: .25rem; */

    background: #2f363e;
    box-shadow: 25px 25px 75px rgba(0, 0, 0, 0.25),
    10px 10px 70px rgba(0, 0, 0, 0.25),
    inset 5px 5px 10px rgba(0, 0, 0, 0.5),
    inset 5px 5px 20px rgba(255, 255, 255, 0.2),
    inset -5px -5px 15px rgba(0, 0, 0, 0.75);
    border-radius: 30px;
}

.profilecard-body {
    flex: 1 1 auto!important;
    min-height: 1px!important;
    padding: 1rem!important;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}
.progress{
    height: 5px;
}
.progress-bar{
    width: 55%;
}