.social-link {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    border-radius: 50%;
    transition: all 0.3s;
    font-size: 0.9rem;
  }
  .list-inline-item a{
    text-decoration: none;
  }
  
  .social-link:hover,
  .social-link:focus {
    background: #ddd;
    text-decoration: none;
    color: #555;
  }

  .digital-column {
    width: 25%;
    border-radius: 15px;
    height: auto;
    flex-grow: 1;
    margin: 2%;
    /* background-color: rgba(68, 68, 68, 0.863); */
    box-shadow: 0 2px 2px #0DCAF0;
  }
  
  .digital-column > .digital-image {
    padding: 5%;
    overflow: hidden;
  }

  .digital-image > img {
    width: 55%;
    border-radius: 50%;
    border: 2px solid #0DCAF0;;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .digital-image > img:hover {
    -webkit-filter: grayscale(0);
    /* Safari 6.0 - 9.0 */
    filter: none;
  }
  @media (max-width: 992px) {
    .digital-column {
      width: 90%;
      /* margin: 3% 15%; */
      margin-bottom: 7%;
    }
    .digital-image {
      padding-top: 7%;
    }
    .digital-card {
      display: block;
    }
  }